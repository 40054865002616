






































































/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */


import { isKey } from '@/components/map/map';
import { asyncUserKeyPermission, getUserMidAndAoiCountByUserKey } from '@/services/serviceApi';
import { Vue, Component } from 'vue-property-decorator';
import { CreateElement } from 'vue/types/vue';
@Component({
  components: {
  },
})
export default class Services extends Vue {
  tableHeight = 600;
  list = [
    {
      id: 1,
      text: '1、需/被同步的均已绑定相同的主场服务区域；',
    },
    {
      id: 2,
      text: '2、需/被同步的主场服务区域均在服务期；',
    },
    {
      id: 3,
      text: '3、需同步的主场区域服务（竞品、AOI）配额足够接待同步过来的数量，配额计算：<br>当前已添加配额 + 同步配额数量 = 总配额，并<= 可添加配额数量；',
    },
  ];
  page = [{
    title: 'mall_id',
    key: 'mall_id',
    align: 'center',
    width: 90,
  },
  {
    title: '项目名称',
    key: 'name',
    align: 'center',
    width: 150,
    // render: (h: CreateElement, params: any) => {
    //   const { name } = params.row;
    //   return h('Poptip', {
    //     domProps: {
    //       trigger: 'hover',
    //       title: '角色详情',
    //       placement: 'bottom',
    //       innerHTML: name,
    //     },
    //   });
    // },
  },
  {
    title: '服务状态',
    key: 'status',
    align: 'center',
    width: 100,
    render: (h: CreateElement, params: any) => {
      const { status } = params.row;
      const html = status === 1 ? '服务中' : '暂停服务';
      return h('div', {
        domProps: {
          innerHTML: html,
        },
      });
    },
  },
  {
    title: '竞品使用详情',
    key: 'competeInfo',
    align: 'center',
  },
  {
    title: '客流来源使用详情',
    key: 'aoiInfo',
    align: 'center',
  }];
  columnPage = [
    {
      type: 'selection',
      align: 'center',
      width: 60,
    },
    ...this.page,
  ];
  data1 = [];
  loading1 = false;
  data2 = [];
  loading2 = false;
  mallObj1: any= {};
  mallObj2: any= {};
  title = 'key权限同步';
  key1 = '';
  key2 = '';
  keyVal1 = '';
  keyVal2 = '';
  tableSelect: any = [];
  syncStauts = false;
  syncObjInfo: any = {
    1: {
      sync: '您确定要同步key下所有关联竞品及AOI区域吗？同步后不可撤销！',
      count: '您要同步的竞品及AOI区域为0,无需同步！',
    },
    2: {
      sync: '您确定要同步key下所有关联AOI区域吗？同步后不可撤销！',
      count: '您要同步的AOI区域为0,无需同步！',
      info: {
        page: 'AOI无法同步：同步过去的AOI超过可添加配额，可在企业服务周期里进行配置后，重新同步',
        no: 'AOI无法同步：已添加的区域数量为0',
      },
    },
    3: {
      sync: '您确定要同步key下所有关联竞品吗？同步后不可撤销！',
      count: '您要同步的竞品区域为0,无需同步！',
      info: {
        page: '竞品无法同步：同步过去的竞品超过可添加配额，可在企业服务周期里进行配置后，重新同步',
        no: '竞品无法同步：已添加的区域数量为0',
      },
    },
  };
  mounted(): void{
    // this.$jQ('#table1').on('click', 'tbody>tr', (e: any) => {
    //   const target = e.currentTarget;
    //   const mallId = Number(this.$jQ(target).children()
    //     .eq(1)
    //     .text());
    //   if (Object.prototype.hasOwnProperty.call(this.mallObj1, mallId)) {
    //     const item = this.mallObj1[mallId];
    //     console.log({ ...item });
    //     this.$Message.info({
    //       duration: 3,
    //       content: item.synchronizeInfo,
    //     });
    //   }
    // });
    // this.key1 = '6M3BZ-CSP3U-ORPVM-2XMEW-RA5CQ-SQFW4';
    // this.key2 = 'SO2BZ-YJ23P-MX4D7-V2GQY-RUOUQ-BRBFG';
    // this.searchClickHandler(this.key1, 1);
    // this.searchClickHandler(this.key2, 2);
    let content = '同步前请先了解同步规则，功能主要是为了同步主场服务区域下的竞品和AOI，必须满足条件可进行同步，配额问题可在企业服务自行修改后进行同步<br>';
    this.list.forEach((item: any) => {
      content += `${item.text}<br>`;
    });
    this.$Modal.info({
      title: `${this.title}使用提示`,
      content,
      onOk: async () => {
        // console.log(1);
      },
    });
  }
  blurChangeHandler(type: number): void{
    if (type === 1) {
      if (this.keyVal1 === this.key1) return;
      this.mallObj1 = {};
      this.data1 = [];
    } else {
      if (this.keyVal2 === this.key2) return;
      this.mallObj2 = {};
      this.data2 = [];
    }
  }

  keyChange(): boolean {
    if (this.keyVal1 === this.key1 && this.keyVal2 === this.key2) return true;
    const content = '检测到您的key 有变动，请重新查询后开始同步';
    this.$Modal.info({
      title: `${this.title}使用提示`,
      content,
      onOk: async () => {
        // console.log(1);
      },
    });
    return false;
  }

  table1RowClick(params: any): void{
    const { synchronizeInfo } = params;
    this.$Message.info({
      duration: 3,
      content: synchronizeInfo,
    });
  }

  tableSelectChangeHandler(row: any): void{
    if (row && (row as any).length) {
      const tableSelectObj: any = {};
      row.forEach((item: any) => {
        const { mall_id: mallId } = item;
        tableSelectObj[mallId] = item;
      });
      this.tableSelect = [];
      Object.values(tableSelectObj).forEach((item: any) => {
        this.tableSelect.push(item);
      });
      this.tableSelect.sort((x: any, y: any) => y._disabled - x._disabled);
    }
  }

  syncClickHandler(type: number): void{
    // type = 1 全部同步  2 aoi 同步  3 竞品同步
    const change = this.keyChange();
    if (!change) return;
    if (this.syncStauts && this.tableSelect.length > 0) {
      const syncAry: number[] = [];
      let syncInfo = '';
      this.tableSelect.forEach((item: any) => {
        const { mall_id: mallId, synchronize, aoiSyncStatus, competeSyncStatus, synchronizeInfo, name } = item;
        if (type === 1) {
          if (synchronize && aoiSyncStatus && competeSyncStatus) {
            syncAry.push(mallId);
          } else {
            syncInfo += `${name}${synchronizeInfo};`;
          }
        } else if (type === 2) {
          if (aoiSyncStatus) {
            syncAry.push(mallId);
          } else {
            syncInfo += `${name}${synchronizeInfo};`;
          }
        } else if (type === 3) {
          if (competeSyncStatus) {
            syncAry.push(mallId);
          } else {
            syncInfo += `${name}${synchronizeInfo};`;
          }
        }
      });
      const infoAry = syncInfo.split(';');
      const { sync } = this.syncObjInfo[type];
      if (syncInfo) {
        this.$Modal.error({
          title: '请将不能提交的区域取消勾选',
          content: syncInfo,
          render: (h?: any) => {
            if (!h) {
              return '';
            }
            const ary: any = [];
            infoAry.forEach((item: string, index: number) => {
              if (item) {
                const index1 = index + 1;
                ary.push(h('p', {
                  domProps: { innerHTML: `${index1}、${item}。` },
                }));
              }
            });
            return h('div', {}, ary);
          },
          onOk: async () => {
            console.log(syncInfo);
          },
        });
      } else {
        this.$Modal.confirm({
          title: this.title,
          content: sync,
          onOk: async () => {
            // 可以同步，调取同步接口
            const params = {
              sync: syncAry,
              key1: this.key1,
              key2: this.key2,
              sync_status: type,
            };
            this.asyncUserKeyPermission(params);
          },
        });
      }
    }
  }

  async asyncUserKeyPermission(params: any): Promise<void> {
    const res = await asyncUserKeyPermission(params);
    if (res.status === 0) {
      console.log('企业权限同步成功');
      const text = res.info;
      this.$Modal.success({
        title: this.title,
        content: text,
        onOk: async () => {
          this.searchClickHandler(this.key2, 2);
          console.log('成功');
        },
      });
    } else {
      const text = '企业权限同步失败，请联系开发同学:v_vjyuan';
      this.$Modal.error({
        title: this.title,
        content: text,
        onOk: async () => {
          console.log('异常');
        },
      });
    }
  }

  async searchClickHandler(key: string, type: number): Promise<void> {
    const keys = this.isKey(key);
    this.syncStauts = false;
    if (keys || this.instanceOfKey()) {
      return;
    }
    const params = {
      user_key: key,
    };
    const { data, mallObj }: any = await this.getUserMidAndAoiCountByUserKey(params, type);
    if (type === 1) {
      this.data1 = data;
      this.mallObj1 = mallObj;
      this.keyVal1 = key;
    } else {
      this.data2 = data;
      this.mallObj2 = mallObj;
      this.keyVal2 = key;
    }
    // 同步策略提前做好判断
    if (this.data1.length > 0 && this.data2.length > 0) {
      Object.keys(this.mallObj1).forEach((key: string) => {
        const item = this.mallObj1[key];
        if (Object.prototype.hasOwnProperty.call(this.mallObj1, key)
        && Object.prototype.hasOwnProperty.call(this.mallObj2, key)) {
          const mallItem2 = this.mallObj2[key];
          const { status: status1, name, aoiCount: aoiCount1, competeCount: competeCount1,
            addCompetedCount: addCompetedCount1, addAoiCount: addAoiCount1, aoiSyncStatus,
            competeSyncStatus } = item;
          const { status: status2, aoiCount: aoiCount2, competeCount: competeCount2,
            addCompetedCount: addCompetedCount2, addAoiCount: addAoiCount2 } = mallItem2;
          // 是否在服务周期中
          if (status1 === 1 && status2 === 1) {
            // 配额问题鉴权
            // 鉴权aoi/竞品是否可以同步
            const aoiStatus = (addAoiCount1 + addAoiCount2) > aoiCount2;
            const competeStatus = (addCompetedCount1 + addCompetedCount2) > competeCount2;

            if (aoiStatus && competeStatus) {
              item._disabled = true;
              item.synchronize = false;
              item.synchronizeInfo = `${this.syncObjInfo[2].info.page},${this.syncObjInfo[3].info.page}`;
              item.aoiSyncStatus = false;
              item.competeSyncStatus = false;
            } else if (addAoiCount1 === 0 && addCompetedCount1 === 0) {
              item._disabled = true;
              item.synchronize = false;
              item.synchronizeInfo = `${this.syncObjInfo[2].info.no},${this.syncObjInfo[3].info.no}`;
              item.aoiSyncStatus = false;
              item.competeSyncStatus = false;
            } else {
              let info = '';
              item.aoiSyncStatus = true;
              item.competeSyncStatus = true;
              item._disabled = false;
              if (!aoiStatus && !competeStatus && addAoiCount1 > 0 && addCompetedCount1 > 0) {
                info = '可以同步；';
                item.synchronize = true;
              } else {
                item.synchronize = false;
                if ((aoiStatus && competeStatus) ||  (addAoiCount1 === 0 && addCompetedCount1 === 0)) {
                  item._disabled = true;
                }
                if (aoiStatus) {
                  item.aoiSyncStatus = false;
                  info += `${this.syncObjInfo[2].info.page}；`;
                }
                if (addAoiCount1 === 0) {
                  item.aoiSyncStatus = false;
                  info += `${this.syncObjInfo[2].info.no}；`;
                }
                if (competeStatus) {
                  item.competeSyncStatus = false;
                  info += `${this.syncObjInfo[3].info.page}；`;
                }
                if (addCompetedCount1 === 0) {
                  item.competeSyncStatus = false;
                  info += `${this.syncObjInfo[3].info.no}；`;
                }
              }
              item.synchronizeInfo = info.slice(0, info.length - 1);
            }
            if (item.synchronize || item.aoiSyncStatus || item.competeSyncStatus) this.syncStauts = true;
          } else {
            item._disabled = true;
            const overdue1 = `被同步的(${name})服务区域已过期，请在企业服务修改服务周期后，进行同步`;
            const overdue2 = `需要同步的(${name})服务区域已过期`;
            const info1 = status1 !== 1 ? overdue1 : '';
            const info2 = status2 !== 1 ? overdue2 : '';
            item.synchronizeInfo = `不可以同步，${info1}${info2}`;
            item.synchronize = false;
          }
        } else {
          item._disabled = true;
          const info = `不可以同步，需要同步的key，添加此区域(${item.name})`;
          item.synchronizeInfo = info;
          item.synchronize = false;
        }
      });
      this.data1 = Object.values(this.mallObj1);
      console.log(this.mallObj1);
    }
    this.data1.sort((x: any, y: any) => x._disabled - y._disabled);
  }

  instanceOfKey(): boolean {
    if (this.key1.trim() === this.key2.trim()) {
      const text = '同步的key与被同步的key不能相同';
      this.$Modal.error({
        title: this.title,
        content: text,
        onOk: async () => {
          console.log(text);
        },
      });
      return true;
    }
    return false;
  }

  isKey(key: string): boolean {
    if (!key || !isKey(key)) {
      const text = 'key格式错误/或者不能为空';
      this.$Modal.error({
        title: this.title,
        content: text,
        onOk: async () => {
          console.log(text);
        },
      });
      return true;
    }
    return false;
  }

  loadingEmit(type: number, status: boolean): void{
    if (type === 1) {
      this.loading1 = status;
    } else {
      this.loading2 = status;
    }
  }

  beforePromise(type: number): void{
    this.loadingEmit(type, true);
  }

  afterPromise(type: number): void{
    this.loadingEmit(type, false);
  }

  async getUserMidAndAoiCountByUserKey(params: any, type: number): Promise<any> {
    this.beforePromise(type);
    const res: any = await getUserMidAndAoiCountByUserKey(params);
    this.afterPromise(type);
    let data: any = [];
    let mallObj: any = {};
    if (res.status !== 1) {
      res.data.forEach((item: any) => {
        const {
          mall_id,
          name,
          province,
          city,
          district,
          serviceCycles,
          // service_cycle_start,
          // service_cycle_end,
          aoi_count,
          compete_count,
          add_competed_count,
          add_aoi_count,
          status,
        } = item;
        const serviceCycle = serviceCycles
          .map((item: any) => `${this.$dayjs(item.service_cycle_start).format('YYYY/MM/DD')}-${this.$dayjs(item.service_cycle_end).format('YYYY/MM/DD')}`)
          .join('\n');
        const aoiInfo = `可添加${aoi_count},  已添加${add_aoi_count}`;
        const competeInfo = `可添加${compete_count},  已添加${add_competed_count}`;
        const take = '已添加';
        const obj = {
          take,
          mall_id,
          name,
          province,
          city,
          district,
          service_cycle: serviceCycle,
          // service_cycle_start,
          // service_cycle_end,
          serviceCycles,
          aoiCount: Number(aoi_count),
          competeCount: Number(compete_count),
          addCompetedCount: Number(add_competed_count),
          addAoiCount: Number(add_aoi_count),
          aoiInfo,
          competeInfo,
          status: Number(status),
          _disabled: false,
          synchronize: false, // 是否可以全部同步的字段
          synchronizeInfo: '', // 是否可以全部同步的字段释义
          aoiSyncStatus: false, // aoi是否可以同步字段
          competeSyncStatus: false, // 竞品是否可以同步字段
        };
        mallObj[mall_id] = obj;
      });
      data = Object.values(mallObj);
    } else {
      this.$Modal.error({
        title: this.title,
        content: '获取企业服务失败',
        onOk: async () => {
          // console.log(1);
        },
      });
      data = [];
      mallObj = {};
    }
    return {
      data,
      mallObj,
    };
  }
}
